import { useTranslation } from 'react-i18next';
import { Segmented } from 'antd';
import { LTLAddressType, LTLAddressTypeLabelMap } from '@/interfaces/ltl-address-type.enum';

interface AddressTypeRadioProps {
	value: LTLAddressType;
	onChange: (value: LTLAddressType) => void;
}

export const AddressTypeRadio = ({ value, onChange }: AddressTypeRadioProps) => {
	const { t } = useTranslation();

	return (
		<>
			<Segmented
				block
				options={Object.entries(LTLAddressTypeLabelMap).map(
					([value, label]: [LTLAddressType, string]) => ({ label: t(label), value }),
				)}
				value={value}
				onChange={onChange}
			/>
			{/* <div className='flex items-center'>
				{Object.entries(LTLAddressTypeLabelMap).map(([val, label]: [LTLAddressType, string]) => {
					const isSelected = val === value;

					return (
						<div
							key={val}
							style={{
								backgroundColor: isSelected ? '#22aedf' : 'white',
								color: isSelected ? 'white' : 'black',
							}}
							className='grow box-border h-[30px] flex justify-center items-center border-[1px] border-solid border-grey-03 cursor-pointer rounded-[4px] body-4-b'
							onClick={() => onChange(val)}
						>
							{t(label)}
						</div>
					);
				})}
			</div> */}
		</>
	);
};
