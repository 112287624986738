import { useTranslation } from 'react-i18next';
import { Input, Tooltip } from 'antd';
import SelectedCircle20pxIcon from '@/icons/selected-circle-20px-icon';
import UnselectedCircle20pxIcon from '@/icons/unselected-circle-20px-icon';
import { InfoCircleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { FormItemTitle } from '../../form-item-title';

interface InsuranceProps {
	disableTitle?: boolean;
}

export const Insurance = observer(({ disableTitle }: InsuranceProps) => {
	const { t } = useTranslation();

	return (
		<div>
			{!disableTitle && (
				<div>
					<div className='h4-b text-[#333333]'>{t(`Insurance`)}</div>
					<Gap height='24px' />
				</div>
			)}
			<div>
				<div className=' box-border p-[12px_12px_12px_8px] w-full flex bg-primary-light-04 rounded-[4px] items-center'>
					<SelectedCircle20pxIcon className='grow-0' />
					<Gap width='8px' />
					<div className='grow flex justify-between gap-[120px] body-3-sb text-black'>
						<div>
							<div>{t(`Basic Carrier Insurance`)}</div>
							<div className='mt-[4px] text-[11px] font-normal'>
								<div>{t(`Max $5,000 in damage and loss.`)} </div>
								<div>
									<a
										href='https://public.drayeasy.com/legal/DrayEasyFreightInsurance.pdf'
										target='_blank'
										className='underline !cursor-pointer'
									>
										{t(`Excluded commodities.`)}
									</a>
								</div>
								<div className='mt-[16px]'>{t(`Need more coverage? Contact us.`)}</div>
							</div>
						</div>
						<div>{t(`Free`)}</div>
					</div>
				</div>

				{/* <div className='box-border p-[15px_8px] flex flex-col'>
					<div className='flex items-center'>
						<UnselectedCircle20pxIcon className='grow-0' />
						<Gap width='8px' />
						<div className='grow flex justify-between items-center'>
							<div>
								<FormItemTitle
									content={t(`Add Extra Insurance for your freight`)}
									disabledGap
									extra={
										<Tooltip style={{ fontSize: '15px' }}>
											<InfoCircleOutlined className='ml-[4px]' />
										</Tooltip>
									}
								/>
							</div>
							<div className='body-3-sb text-black'>{t(`+ $0`)}</div>
						</div>
					</div>

					<Gap height='8px' />

					<div className='pl-[28px] flex flex-col'>
						<Input />

						<Gap height='12px' />

						<div className='flex'>
							<div>
								<FormItemTitle content='Number of Total Pieces' required />
								<Input />
							</div>

							<Gap width='12px' />

							<div>
								<FormItemTitle content='Declare Value' required />
								<Input />
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
});
