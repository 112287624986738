import { DraftOrder } from '@/interfaces/draft-order.interface';
import { Order } from '@/interfaces/order.interface';
import { getDraftOrders, getOrder, getOrders } from '@/services/apis';
import { getDrayOrders } from '@/services/apis/get-agent-orders';
import { makeAutoObservable, runInAction } from 'mobx';
import qs from 'qs';

class OrderContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _draftOrders: DraftOrder[] | undefined;

	private _orders: Order[] | undefined;

	private _isFetchingOrders = false;

	get draftOrders() {
		return this._draftOrders;
	}

	set draftOrders(draftOrders: typeof this._draftOrders) {
		runInAction(() => {
			this._draftOrders = draftOrders;
		});
	}

	get orders() {
		return this._orders;
	}

	set orders(orders: typeof this._orders) {
		runInAction(() => {
			this._orders = orders;
		});
	}

	get isFetchingOrders() {
		return this._isFetchingOrders;
	}

	set isFetchingOrders(isFetchingOrders: typeof this._isFetchingOrders) {
		runInAction(() => {
			this._isFetchingOrders = isFetchingOrders;
		});
	}

	async fetchOrders(
		props: Parameters<typeof getOrders>[0],
		config?: {
			autoSet?: boolean;
			fromParams?: boolean;
		},
	) {
		if (config?.fromParams) {
			props = {
				params: qs.parse(window.location.search, { ignoreQueryPrefix: true }),
			} as unknown as Parameters<typeof getOrders>[0];
		}
		const res = await getOrders(props);
		if (config?.autoSet) {
			this.orders = res.data.data;
		}
		return res;
	}

	async fetchAgentDrayOrders(
		props: Parameters<typeof getDrayOrders>[0],
		config?: {
			autoSet?: boolean;
		},
	) {
		const res = await getDrayOrders(props);
		if (config?.autoSet) {
			this.orders = res.data.data;
		}
		return res;
	}

	async fetchDraftOrders(
		props: Parameters<typeof getDraftOrders>[0],
		config?: {
			autoSet?: boolean;
		},
	) {
		const res = await getDraftOrders(props);
		if (config?.autoSet) {
			this._draftOrders = res.data.data;
		}
		return res.data;
	}

	async fetchOrder(props: Parameters<typeof getOrder>[0]) {
		const res = await getOrder(props);
		return res.data;
	}

	async refreshOrders() {
		return await this.fetchOrders(
			{
				params: {
					page: 1,
				},
			},
			{
				fromParams: true,
				autoSet: true,
			},
		);
	}
}

export const order = new OrderContext();
