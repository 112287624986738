import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from 'axios';
import { GetLTLAutofillProps, GetLTLAutofillRes } from './interface';

export * from './interface';

const URL = import.meta.env.VITE_AUTOFILL_AI_URL;

export const getLTLAIAutofill = async (props: GetLTLAutofillProps) => {
	return await axios.post<GetLTLAutofillRes>(URL, props.data, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
