import { observer } from 'mobx-react-lite';
import { FormItemTitle, FormItemTitleProps } from './form-item-title';

interface FormItemProps {
	titleProps: FormItemTitleProps;
	children: React.ReactNode;
}

export const FormItem = observer(({ titleProps, children }: FormItemProps) => {
	return (
		<div className='flex flex-col'>
			<FormItemTitle {...titleProps} />
			{children}
		</div>
	);
});
