import { useTranslation } from 'react-i18next';
import { Divider, Space, Timeline, Typography } from 'antd';
import { businessStatusMap } from '@/interfaces/shipment.interface';
import { downloadLtlFile } from '@/services/apis/download-ltl-file';
import {
	calcTotalWeight,
	formatDate,
	generateNumberOfUnits,
} from '@/services/contexts/ltl/ltl.context';
import { ArrowRightOutlined, CloudDownloadOutlined, SmileOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { MapBox } from '../../search-rate/components/mapbox';
import { Item } from '../item';
import { useLtlOrder } from '../use-ltl-order';

const { Text } = Typography;

export const SideShipmentInfo = () => {
	const { t } = useTranslation();

	const { data } = useLtlOrder();

	const accessorials = uniq([
		...(data.destination_accessorials ?? []),
		...(data.pickup_accessorials ?? []),
		...(data.shipment_accessorials ?? []),
	])
		.filter((arr) => arr)
		.join(',');

	return (
		<div className=' box-border grow-0 overflow-hidden  w-[382px] h-fit border-[1px] border-solid border-grey-05'>
			<div className='w-[full] h-[180px]'>
				<MapBox start={data.pickup_city} to={data.destination_city} />
			</div>

			<div className=' box-border p-[24px_24px_36px_24px] w-full'>
				<div className='flex flex-col gap-[16px]'>
					<div className='flex flex-col gap-[4px]'>
						<div className='flex items-center gap-[4px] h4-b text-[#333333]'>
							<div>{`${data.pickup_city.full_name.split(',').slice(0, 2).join(',')}`}</div>
							<div>
								<ArrowRightOutlined />
							</div>
							<div>{`${data.destination_city.full_name.split(',').slice(0, 2).join(',')}`}</div>
						</div>
						<div className='flex items-center gap-[4px] body-3-r text-grey-01'>
							<span>{t(`Pickup`)}</span>
							<span>{formatDate(data.estimated_pickup_date)}</span>
						</div>
					</div>
					<Item
						title={t(`Status`)}
						content={
							<Typography.Text ellipsis>
								{businessStatusMap[data.business_status] ?? ''}
							</Typography.Text>
						}
					/>
					<div className='flex gap-[83px]'>
						<Item title={t(`Total weight`)} content={`${calcTotalWeight(data.items)} lbs`} />
						<Item
							title={t(`Number of units`)}
							content={
								<Typography.Text ellipsis>{generateNumberOfUnits(data.items)}</Typography.Text>
							}
						/>
					</div>
					<Item
						title={t(`Commodity Name`)}
						content={data.items.map((item) => item.description).join(',')}
					/>
					<Item
						title={t(`Accessorials`)}
						content={<Typography.Text ellipsis>{accessorials}</Typography.Text>}
					/>
				</div>

				<Divider />

				<div className='flex flex-col gap-[16px]'>
					<Item title={t(`Carrier`)} content={data.vendor_carrier_name} />

					<div className='grid grid-cols-[auto_auto] grid-rows-[auto_auto] gap-x-[36px] gap-y-[16px]'>
						<Item
							title={t(`Estimated Delivery`)}
							content={formatDate(data.estimated_delivery_date)}
						/>
						<Item
							title={t(`Transit Time`)}
							content={t(`${data.quote_transit_days ?? '--'} days`)}
						/>
						<Item title={t(`Base Price`)} content={t(`$${data.sell_rate}`)} />
						<Item title={t(`Extra Insurance`)} content='---' />
					</div>
				</div>

				<Divider />

				<Timeline
					items={
						data.tracking_histories
							? data.tracking_histories.map((track) => ({
									children: (
										<Space direction='vertical'>
											<Text strong>{track.status}</Text>
											<Text italic>{dayjs(track.timestamp).format('MM/DD/YYYY HH:mm')}</Text>
										</Space>
									),
									color: track.status === 'Completed' ? 'green' : 'blue',
									dot: track.status === 'Completed' ? <SmileOutlined /> : null,
							  }))
							: []
					}
				/>

				{/* <Divider /> */}

				<div className='grid grid-cols-[auto_auto] grid-rows-[auto_auto] gap-x-[36px] gap-y-[16px]'>
					{data.vendor_documents.map((doc) => (
						<Item
							title={t(doc.type)}
							content={
								<div
									className='flex items-center gap-[8px] cursor-pointer'
									onClick={() =>
										downloadLtlFile({
											documentId: doc.id,
											fileName: doc.file_name,
											shipmentId: data.id,
										})
									}
								>
									<span>{doc.name}</span>
									<CloudDownloadOutlined style={{ fontSize: '18px' }} />
								</div>
							}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
