import { ReactNode, useEffect, useMemo } from 'react';
import { Button, Col, Form, Row } from 'antd';
import ContainerIcon from '@/icons/container-icon';
import { LIVE_OR_DOPR_MAP } from '@/interfaces/live-or-drop.enum';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { getWarehouse } from '@/services/apis';
import { useBookContext, useGlobalContext, useSearchRateContext } from '@/services/contexts';
import { t } from 'i18next';
import { upperCase } from 'lodash';
import { Timeline } from 'rsuite';
import { isRampPort } from '@/utils/auto-determine-destination-port';
import { cargoTypesMap } from '@/utils/cargo-types';
import { Container } from '../container-info/type';
import './index.less';

const ContainerInfo = ({ container }: { container: Container }) => {
	return (
		<div className='ml-[25px]'>
			{/* id */}
			<div>{container.number}</div>

			{/* content */}
			<div className='flex'>
				{/* pkg */}
				<div className='flex mr-[10px]'>
					{/* num */}
					<div className='flex items-center mr-[10px]'>
						<div>{container.package}</div>
						<div>X</div>
					</div>
					<ContainerIcon />
				</div>

				{/* tags */}
				<div>
					<div>
						{container.type}, {container.package} {t('Package Count')}
					</div>
					<div>
						{/* @ts-ignore */}
						{container.weight}
						{upperCase(container.weight_unit)},{' '}
						{Object.entries(cargoTypesMap)
							// @ts-ignore
							.filter(([k, v]) => container[k])
							.map(([_, v]) => t(v.label))
							.join(' ')}
						{container.soc_return_location && `, ${container.soc_return_location.address}`}
					</div>
				</div>
			</div>
		</div>
	);
};

export default function ({ goStep }: { goStep: (step: number) => any }) {
	const form = Form.useFormInstance();

	const searchRateContext = useSearchRateContext();

	const { cacheContext } = useGlobalContext();

	const bookContext = useBookContext();

	const selectedWarehouse = Form.useWatch('warehouse');

	const finalPortId = Form.useWatch('final_port_id');

	const finalPort = cacheContext.intermodalRegions.find((i) => i.id === finalPortId);

	const portOfDischargeId = Form.useWatch('port_of_discharge_id');

	const isRamp = useMemo(
		() => isRampPort(portOfDischargeId, finalPortId),
		[portOfDischargeId, finalPortId],
	);

	const { draftOrderId, draftOrder } = bookContext;

	// route info
	// pickup from
	const pickupTerminalId = Form.useWatch('terminal_id', form);
	const pickupTerminal: { name: string } = (() => {
		if (draftOrderId) {
			return draftOrder?.terminal as { name: string };
		}
		if (searchRateContext.selectedSearchData?.terminals?.length) {
			const terminal = searchRateContext.selectedSearchData.terminals.find(
				(t) => t.id === pickupTerminalId,
			);
			if (terminal) return terminal;
		}
		return finalPort ?? { name: '' };
	})();

	const liveOrDrop = Form.useWatch('live_or_drop');

	// container info
	const containers: Container[] = Form.useWatch('containers', form);

	// carrier info
	// MBL
	const MBL = Form.useWatch('mbl_number', form);
	const puNumberAgent = Form.useWatch('pickup_number_agent', form);
	// Commodity
	const commodity = Form.useWatch('commodity', form);
	// Extra Email
	const extraEmails = Form.useWatch('contact_emails', form) ?? [];
	// Delivery Instructions
	const customer_memo = Form.useWatch('customer_memo', form);

	const DotLabel = ({ label, index }: { index: number; label: string }) => {
		return (
			<div className='flex justify-between font-[700] text-[16px]'>
				<div>{label}</div>
				<Button type='primary' ghost onClick={() => goStep(index)}>
					{t('Edit')}
				</Button>
			</div>
		);
	};

	const Content = ({ label, content }: { label: string; content: ReactNode }) => {
		return (
			<Row className='ml-[32px]' wrap={false} gutter={5}>
				<Col flex='120px' className='font-[500] text-[16px] whitespace-nowrap'>
					{label}
				</Col>
				<Col flex='auto' className='font-[400] text-[16px] text-[#000000B2]'>
					{content}
				</Col>
			</Row>
		);
	};

	return (
		<Timeline>
			<Timeline.Item>
				<div className='ml-[12px]'>
					<DotLabel label={t('Route Info')} index={0} />
					<Content label={t('Pickup from')} content={<span>{pickupTerminal?.name}</span>} />
					<Content
						label={t('Delivery to')}
						content={<span>{selectedWarehouse?.delivery_order_address}</span>}
					/>
					<Content
						label={t('Delivery Type')}
						content={<span>{LIVE_OR_DOPR_MAP[liveOrDrop]}</span>}
					/>
				</div>
			</Timeline.Item>
			<Timeline.Item>
				<div className='ml-[12px]'>
					<DotLabel label={t('Container Info')} index={1} />
					{containers &&
						containers.map((c) => (
							<Content label={t('Container ID')} content={<ContainerInfo container={c} />} />
						))}
				</div>
			</Timeline.Item>
			<Timeline.Item>
				<div className='ml-[12px]'>
					<DotLabel label={t('Carrier Info')} index={2} />
					<Content label={t('MBL')} content={<>{MBL}</>} />
					{isRamp && <Content label={t('PU# Agent')} content={<>{puNumberAgent?.name}</>} />}

					<Content label={t('Commodity')} content={<span>{commodity}</span>} />
					<Content label={t('Extra Email')} content={<span>{extraEmails.join(', ')}</span>} />
					<Content label={t('DEL Instruction')} content={<span>{customer_memo}</span>} />
				</div>
			</Timeline.Item>
		</Timeline>
	);
}
