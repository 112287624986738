import { useNavigate } from 'react-router-dom';
import OrderDetailArrowLeftIcon from '@/icons/order-detail-arrow-left-icon';
import { t } from 'i18next';

export default () => {
	const navigate = useNavigate();

	return (
		<div className='flex items-center cursor-pointer' onClick={() => navigate(-1)}>
			<OrderDetailArrowLeftIcon />
			<span className='ml-[2px] font-[500] text-[16px] text-[#747578]'>{t('Back')}</span>
		</div>
	);
};
