import { LTLAddressTypeLabelMap } from '@/interfaces/ltl-address-type.enum';
import { pickupAccesorials } from '@/interfaces/quote.interface';
import { useLTLContext } from '@/services/contexts';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

export const ShipmentInfoPickupAddress = observer(() => {
	const ltlContext = useLTLContext();

	const pickupAddress = ltlContext.pickupAddress;

	const pickupAddressType = ltlContext.pickUpAddressType;

	const pickupAccessorials = ltlContext.stepOneFormValues.pickup_accessorials ?? [];

	return (
		<div className='flex flex-col gap-[4px] h-full'>
			<div className='h4-b'>{`${pickupAddress.city.full_name.split(',').slice(0, 2).join(',')} ${
				pickupAddress.zipcode
			}`}</div>
			<div className='grow body-3-r'>{t(LTLAddressTypeLabelMap[pickupAddressType])}</div>
			<div className='body-3-r'>
				{pickupAccessorials
					.map((k) => pickupAccesorials.find((i) => i.key === k)?.name ?? '')
					.join(', ')}
			</div>
		</div>
	);
});
