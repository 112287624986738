import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonProps, Form, FormProps, message } from 'antd';
import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { Order } from '@/interfaces/order.interface';
import { WeightUnit } from '@/interfaces/weight-unit.enum';
import { draftOrCreateOrder, isOrderExpired, updateOrder } from '@/services/apis';
import { DraftOrCreateOrderRes } from '@/services/apis/draft-or-create-order/interface';
import { UpdateOrderRes } from '@/services/apis/update-order/interface';
import { useBookContext, useGlobalContext, useSearchRateContext } from '@/services/contexts';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { StepProps as RcStepProps } from 'rc-steps/lib/Step';
import BackButton from './components/back-button';
import { CarrierDetails } from './components/carrier-details';
import { DEFAULT_PICKUP_NUMBER_AGENT } from './components/carrier-details/components/pickup-number-agent-select';
import { Confirmation } from './components/confirmation';
import { ContainerInfo } from './components/container-info';
import ExitButton from './components/exit-button';
import Finish from './components/finish';
import NextButton from './components/next-button';
import OrderButton from './components/order-button';
import { RouteInfo } from './components/route-info';
import SaveDraftButton from './components/save-draft-button';
import CenterSpin from '@/components/center-spin';
import useBookQs from './hooks/use-book-qs';
import useSteps from '@/hooks/use-steps';
import calcIsOverWeight from '@/utils/calc-is-over-weight';
import calcWeight from '@/utils/calc-weight';
import { extractLeadingNumbers } from '@/utils/extract-leading-numbers';

const STATE_DRAFT = 'draft';
const STATE_OPEN = 'open';

export const Book = observer(() => {
	const items: RcStepProps[] = [
		{ title: t('Route Info'), icon: <div></div> },
		{ title: t('Container Info'), icon: <div></div> },
		{ title: t('Carrier Info'), icon: <div></div> },
		{ title: t('Confirmation'), icon: <div></div> },
	];

	const { Steps, current, setCurrent } = useSteps(items);

	const [isOrderFinished, setIsOrderFinished] = useState<'waiting' | 'posting'>('waiting');

	const [isDraftOrderFinished, setIsDraftOrderFinished] = useState<'waiting' | 'posting'>(
		'waiting',
	);

	const { parsedSearchParams } = useBookQs();

	const draftOrderId = parsedSearchParams.draftOrderId;

	const orderId = parsedSearchParams.orderId;

	const rid = parsedSearchParams.rid;

	const [bookedOrderId, setBookedOrderId] = useState<number | undefined>();

	const [bookedOrder, setBookedOrder] = useState<
		UpdateOrderRes['data'] | DraftOrCreateOrderRes['data'] | undefined
	>();

	const [form] = Form.useForm();

	const searchRateContext = useSearchRateContext();

	const bookContext = useBookContext();

	const { cacheContext } = useGlobalContext();

	bookContext.useDraftOrder = Boolean(draftOrderId || orderId);

	const navigate = useNavigate();

	const draftOrder = bookContext.draftOrder;

	const selectedSearchData = searchRateContext.selectedSearchData;

	const getInitialValues = () => {
		if (bookContext.useDraftOrder) {
			const _containers = draftOrder?.containers
				? draftOrder?.containers.map((c) => {
						if (draftOrderId) {
							return { ...c, weight_unit: WeightUnit.KG };
						}

						const { id, ...rest } = c;
						return { ...rest, weight_unit: WeightUnit.KG };
				  })
				: draftOrder?.containers;

			return {
				port_of_discharge_id: draftOrder?.port_of_discharge?.id,
				final_port_id: draftOrder?.final_port?.id,
				terminal_id: parsedSearchParams.useSearchContext
					? searchRateContext.selectedSearchData?.terminals?.[0]?.id
					: draftOrder?.terminal?.id,

				port_of_discharge_eta: dayjs(draftOrder?.port_of_discharge_eta),
				final_port_eta: dayjs(draftOrder?.final_port_eta),
				live_or_drop: draftOrder?.live_or_drop,
				warehouse: draftOrder?.warehouse,

				containers: _containers,

				mbl_number: draftOrder?.mbl_number,
				commodity: draftOrder?.commodity,
				contact_emails: draftOrder?.contact_emails ?? [''],
				customer_memo: draftOrder?.customer_memo,
				files: draftOrderId ? draftOrder?.files : [],
				pickup_number_agent: draftOrder?.pickup_number_agent,
			};
		}

		return {
			port_of_discharge_id: selectedSearchData?.intermodal_region?.has_ocean_port
				? selectedSearchData?.intermodal_region?.id
				: undefined,
			final_port_id: selectedSearchData?.intermodal_region?.id,
			terminal_id: searchRateContext.selectedSearchData?.terminals?.[0]?.id,
			live_or_drop: searchRateContext.liveOrDrop,
			contact_emails: [''],
			files: [],
			pickup_number_agent: DEFAULT_PICKUP_NUMBER_AGENT,
		};
	};

	const initialValues = getInitialValues();

	const rate_id =
		bookContext.useDraftOrder && !parsedSearchParams.useSearchContext
			? draftOrder?.rate?.id
			: searchRateContext.selectedSearchData?.id;

	const rate_uid =
		bookContext.useDraftOrder && !parsedSearchParams.useSearchContext
			? draftOrder?.rate?.uid
			: searchRateContext.selectedSearchData?.uid;

	const goNext = () => {
		setCurrent((current) => (current < items.length - 1 ? current + 1 : current));
	};

	const goPre = () => {
		setCurrent((current) => (current > 0 ? current - 1 : current));
	};

	const createOrder = async () => {
		const res = await handleDraftOrCreateOrder('open');

		res?.data?.data && setBookedOrder(res.data?.data);
	};

	const StepContent = ({ title, content }: { title: string; content: React.ReactNode }) => {
		return (
			<div
				style={{ display: items[current].title === title ? 'block' : 'none' }}
				className='h-full'
			>
				{content}
			</div>
		);
	};

	const nextButton = ({ onClick, ...props }: ButtonProps) => (
		<NextButton
			{...props}
			onClick={async (e) => {
				onClick && (await onClick(e as any));
				goNext();
			}}
		/>
	);

	const backButton = ({ onClick, ...props }: ButtonProps) => (
		<BackButton
			{...props}
			onClick={(e) => {
				onClick && onClick(e as any);
				goPre();
			}}
		/>
	);

	const orderButton = ({ onClick, ...props }: ButtonProps) => (
		<OrderButton
			{...props}
			loading={isOrderFinished === 'posting'}
			onClick={(e) => {
				onClick && onClick(e as any);
				createOrder();
			}}
		/>
	);

	const saveDraftButton = ({ onClick, ...props }: ButtonProps) => (
		<SaveDraftButton
			{...props}
			loading={isDraftOrderFinished === 'posting'}
			onClick={(e) => {
				onClick && onClick(e as any);
				handleDraftOrCreateOrder('draft');
			}}
		/>
	);

	const stepContents = [
		{
			title: t('Route Info'),
			content: <RouteInfo NextButton={nextButton} SaveDraftButton={saveDraftButton} />,
		},
		{
			title: t('Container Info'),
			content: (
				<Form.Item noStyle name='containers'>
					<ContainerInfo
						NextButton={nextButton}
						BackButton={backButton}
						SaveDraftButton={saveDraftButton}
					/>
				</Form.Item>
			),
		},
		{
			title: t('Carrier Info'),
			content: (
				<CarrierDetails
					NextButton={nextButton}
					BackButton={backButton}
					SaveDraftButton={saveDraftButton}
				/>
			),
		},
		{
			title: t('Confirmation'),
			content: (
				<Confirmation
					BackButton={backButton}
					OrderButton={orderButton}
					goStep={setCurrent}
					SaveDraftButton={saveDraftButton}
				/>
			),
		},
	];

	// 自动标记 OW
	const watchContainerChanges: FormProps['onValuesChange'] = (changedValue, values) => {
		const containers = changedValue['containers'];
		if (!containers) return;

		const index = Object.keys(containers)[0];

		const container = values['containers'][index];

		if (!container['type'] && !container['weight']) return;

		const type = container['type'];
		const weight = Number(container['weight']);
		const unit = container['weight_unit'];
		// const weight = calcWeight(, container['weight_unit']);

		const isReefer = String(type).endsWith('RF');
		const size = extractLeadingNumbers(String(type));

		if (!size) return;

		const namePath = ['containers', index, 'is_overweight'];
		const preIsOverweight = container['is_overweight'];
		const isOverweight = calcIsOverWeight(isReefer, size, weight, unit);

		if ((isOverweight && !preIsOverweight) || (!isOverweight && preIsOverweight)) {
			form.setFieldValue(namePath, isOverweight);
		}
	};

	const handleValuesChange: FormProps['onValuesChange'] = (changedValue, values) => {
		watchContainerChanges(changedValue, values);
	};

	const canSaveDraftOrder = async () => {
		if (!draftOrderId) {
			return true;
		}

		const res = await isOrderExpired({ path: { orderId: draftOrder.id } });

		const is_expired = res.data.is_expired;

		if (is_expired) {
			message.error(t('The rate for this draft has expired. Not editable.'));
			return false;
		}

		return true;
	};

	const handleDraftOrCreateOrder = _.once(async (state: 'draft' | 'open') => {
		const isDraftState = state === STATE_DRAFT;
		const isOpenState = state === STATE_OPEN;

		if (isDraftState && !(await canSaveDraftOrder())) {
			return;
		}

		let res;
		try {
			if (isDraftState && isDraftOrderFinished !== 'waiting') return;
			if (isOpenState && isOrderFinished !== 'waiting') return;

			isDraftState && setIsDraftOrderFinished('posting');
			isOpenState && setIsOrderFinished('posting');

			const values = form.getFieldsValue();

			const {
				port_of_discharge_id,
				port_of_discharge_eta,
				final_port_id,
				final_port_eta,
				terminal_id,
				live_or_drop,
				warehouse,

				containers,
				customer_reference_number,
				mbl_number,
				commodity,
				contact_emails,
				pickup_number_agent,
				customer_memo,
				files,
			} = values;

			if (!rate_id || !rate_uid) {
				console.error(`no rate id or rate uid`, rate_id, rate_uid);
				return;
			}

			const data = {
				state,
				rate_id,
				rate_uid,

				port_of_discharge_id,
				port_of_discharge_eta: dayjs(port_of_discharge_eta).format('YYYY-MM-DD'),
				final_port_id,
				final_port_eta: dayjs(final_port_eta).format('YYYY-MM-DD'),
				terminal_id,
				live_or_drop,
				warehouse_id: warehouse.id,
				customer_reference_number,
				containers: containers
					? containers.map((c) => {
							const weight = calcWeight(c.weight, c.weight_unit, WeightUnit.KG);
							return {
								...c,
								weight,
							};
					  })
					: [],

				mbl_number,
				commodity,
				pu_number_agent_id: pickup_number_agent?.id,
				contact_emails: contact_emails?.filter((e) => e !== '') ?? [],
				customer_memo,
				// @ts-ignore
				files: files.map((f) => ({ id: f.id })),
			};

			if (isOpenState) {
				if (draftOrderId) {
					res = await updateOrder({ path: { orderId: Number(draftOrderId) }, data });
				} else {
					res = await draftOrCreateOrder({ data });
				}

				!draftOrderId && message.success(t('Create order success'));
				draftOrderId && message.success(t('Create draft order success'));
			} else {
				if (draftOrderId) {
					res = await updateOrder({ path: { orderId: Number(draftOrderId) }, data });
				} else {
					res = await draftOrCreateOrder({ data });
					navigate('/orders');
				}

				message.success(t('Save draft order success'));
			}

			// if (state === 'draft' && draftOrderId) {
			// 	res = await updateOrder({ path: { orderId: Number(draftOrderId) }, data });
			// 	message.success(t('Save draft order success'));
			// } else {
			// 	res = await draftOrCreateOrder({ data });
			// 	state === 'open' && message.success(t('Create order success'));
			// 	state === 'draft' && message.success(t('Create draft order success'));
			// }
		} catch (error: any) {
			isDraftState && message.error(`${t('Save draft error')}: ${error?.response?.data?.message}`);
			isOpenState && message.error(`${t('Save order error')}: ${error?.response?.data?.message}`);
		} finally {
			isDraftState && setIsDraftOrderFinished('waiting');
			isOpenState && setIsOrderFinished('waiting');
		}
		return res;
	});

	useEffect(() => {
		bookContext.draftOrderId = draftOrderId ? Number(draftOrderId) : undefined;
		bookContext.orderId = orderId ? Number(orderId) : undefined;
	}, [draftOrderId, orderId]);

	const updateFormByRid = async (rid) => {
		if (rid) {
			const rate = await bookContext.fetchRate(rid);
			// form.setFieldValue('port_of_discharge_id', rate?.intermodal_region?.id);
			searchRateContext.selectedSearchData = {
				...searchRateContext.selectedSearchData,
				...rate,
			};
			if (rate?.intermodal_region?.has_ocean_port) {
				form.setFieldsValue({
					port_of_discharge_id: rate?.intermodal_region?.id,
					final_port_id: rate?.intermodal_region?.id,
					terminal_id: rate?.terminals[0]?.id || 0,
				});
			} else {
				form.setFieldsValue({
					final_port_id: rate?.intermodal_region?.id,
					terminal_id: rate?.terminals[0]?.id || 0,
				});
			}

			navigate('/search-rate/book');
		}
	};

	useEffect(() => {
		updateFormByRid(rid);
	}, [rid]);

	if (bookedOrder) return <Finish order={bookedOrder} />;

	if (
		!cacheContext.intermodalRegions ||
		!cacheContext.intermodalRegions.length ||
		(draftOrderId && !draftOrder) ||
		(orderId && !draftOrder)
	)
		return <CenterSpin size='large' />;

	return (
		<div className='flex flex-col pt-[32px] pb-[22px] h-full bg-[#FAFBFF]'>
			<div className='mb-[29px] font-[700] text-[30px] text-center'>{t('Book')}</div>
			<div className='flex justify-center mb-[47px]'>
				<Steps />
			</div>

			<div className='absolute top-[52px] left-[260px]'>
				<ExitButton />
			</div>

			<Form
				form={form}
				initialValues={initialValues}
				layout='vertical'
				style={{ height: 'calc(100vh - 32px - 22px - 43px - 29px - 44px - 47px)' }}
				onFinish={console.log}
				onValuesChange={handleValuesChange}
			>
				{stepContents.map((c) => (
					<StepContent key={c.title} {...c} />
				))}
			</Form>
		</div>
	);
});
