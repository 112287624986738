import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'antd';
import { useLTLContext } from '@/services/contexts';
import { calcTotalVolume, calcTotalWeight } from '@/services/contexts/ltl/ltl.context';
import { userContext } from '@/services/contexts/user';
import { TYPE_AGENT } from '@/services/contexts/user/constant';
import { observer } from 'mobx-react-lite';
import { FT3 } from '@/components/ft3';
import { Gap } from '@/components/gap';

const ItemContainer = (props: { item1: React.ReactNode; item2: React.ReactNode }) => (
	<div className='h-[57px] flex flex-col justify-between'>
		<div className='body-3-r'>{props.item1}</div>
		<div className='h4-b text-[#333333]'>{props.item2}</div>
	</div>
);

const Placeholder = () => <div className='h-[1.5px] w-[32px] bg-black'></div>;

export const Footer = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();
	const user = userContext.getUserInfo();
	const primaryText =
		ltlContext.step === 1
			? t(`Next`)
			: ltlContext.step === 2
			? t(`Select Carrier`)
			: t(`Place Order`);

	return (
		<div className=' box-border w-full h-[130px] flex justify-between items-center'>
			<div className='grow shrink flex justify-between items-center'>
				<ItemContainer
					item1={t(`step n of m`, { n: ltlContext.step, m: 3 })}
					item2={<div className='h-[4px] w-[150px] bg-primary-regular rounded-[50px]'></div>}
				/>

				<ItemContainer
					item1={t(`Total weight`)}
					item2={<span>{`${calcTotalWeight(ltlContext.stepOneFormValues.items)} lbs`}</span>}
				/>

				<ItemContainer
					item1={t(`Total volume`)}
					item2={
						<span>
							{`${calcTotalVolume(ltlContext.stepOneFormValues.items)} `}
							<FT3 />
						</span>
					}
				/>

				<ItemContainer
					item1={t(`Total items`)}
					item2={`${ltlContext.stepOneFormValues.items.length} items`}
				/>

				<ItemContainer
					item1={t(`Total price`)}
					item2={
						ltlContext.selectedQuoteRate?.rate ? (
							t(`$${ltlContext.selectedQuoteRate.rate}`)
						) : (
							<Placeholder />
						)
					}
				/>
			</div>

			<Gap width='100px' />

			<div className='grow-0 shrink-0 flex flex-col items-center'>
				<div className='flex items-center'>
					{ltlContext.step == 3 && user.type == TYPE_AGENT ? (
						<></>
					) : (
						<Button
							type='primary'
							className='min-w-[240px] h-[48px] text-[18px] font-[600] text-white'
							onClick={() => ltlContext.stepNextHandler()}
							{...ltlContext.stepNextButtonProps}
						>
							{primaryText}
						</Button>
					)}
					<Gap width='12px' />
					{/* <Button type='primary' ghost className='w-[160px] h-[48px] text-[18px] font-[600]'>
						{t(`Save as draft`)}
					</Button> */}
				</div>
				<Gap height='12px' />
				<div className='flex items-center'>
					<div className='body-3-r'>{t(`Balance`) + `: $${ltlContext.balance}`}</div>
					{/* <Divider type='vertical' />
					<div className='body-3-r'>{t(`Wallet Balance: $n`, { n: 1000 })}</div> */}
				</div>
			</div>
		</div>
	);
});
