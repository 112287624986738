import { useTranslation } from 'react-i18next';
import { Button, Input, message, Modal } from 'antd';
import Shining10pxIcon from '@/icons/shining-10px-icon';
import { useLTLContext } from '@/services/contexts';
import { useBoolean, useMemoizedFn, useSafeState } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';

export const LTLAIAutofillBtn = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	const [content, setContent] = useSafeState('');

	const [openingModal, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);

	const { runAsync: getLTLAutofill } = apiHooks.useGetLTLAutofill();

	const { runAsync: searchCity } = apiHooks.useSearchCity();

	const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(false);

	const handleContentKeydown = (e) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			if (!content) {
				setContent(t('AUTOFILL_PLACEHOLDER_TEXT'));
			}
		}
	};

	const handleAIAutofill = useMemoizedFn(async () => {
		try {
			startLoading();

			const res = await getLTLAutofill({ data: { content } });
			const data = res.data;

			let pickupAddress: typeof ltlContext.pickupAddress,
				destinationAddress: typeof ltlContext.destinationAddress;

			if (data.pickup_city_name || data.pickup_zipcode) {
				let cityName = '';
				if (data.pickup_city_name) {
					cityName = data.pickup_city_name;
					if (data.pickup_state_name) {
						cityName += `, ${data.pickup_state_name} `;
					}
				}
				if (data.pickup_zipcode) {
					cityName += `${data.pickup_zipcode}`;
				}
				const pickupCityRes = await searchCity({ path: { cityName } });

				if (pickupCityRes.data.data.length > 0) {
					const _pickupCity = pickupCityRes.data.data[0];
					pickupAddress = {
						id: -1,
						name: '',
						code: null,
						zipcode: _pickupCity.zipcode,
						is_residential: false,
						address: data.pickup_address1,
						address2: data.pickup_address2,
						special_request: '',
						city: _pickupCity,
						country: _pickupCity.country,
						customer_memo: '',
						appointment_by: -1,
						created_at: '',
						updated_at: '',
						delivery_order_address: '',
						contacts: [
							// @ts-ignore
							{
								name: '',
								email: '',
								phone: '',
							},
						],
						// @ts-ignore
						user: {},
					};
				}
			}

			if (data.destination_city_name || data.destination_zipcode) {
				let cityName = '';
				if (data.destination_city_name) {
					cityName = data.destination_city_name;
					if (data.destination_state_name) {
						cityName += `, ${data.destination_state_name} `;
					}
				}
				if (data.destination_zipcode) {
					cityName += `${data.destination_zipcode}`;
				}
				const destinationCityRes = await searchCity({ path: { cityName } });

				if (destinationCityRes.data.data.length > 0) {
					const _destinationCity = destinationCityRes.data.data[0];
					destinationAddress = {
						id: -1,
						name: '',
						code: null,
						zipcode: _destinationCity.zipcode,
						is_residential: false,
						address: data.destination_address1,
						address2: data.destination_address2,
						special_request: '',
						city: _destinationCity,
						country: _destinationCity.country,
						customer_memo: '',
						appointment_by: -1,
						created_at: '',
						updated_at: '',
						delivery_order_address: '',
						contacts: [
							// @ts-ignore
							{
								name: '',
								email: '',
								phone: '',
							},
						],
						// @ts-ignore
						user: {},
					};
				}
			}

			if (pickupAddress) {
				ltlContext.pickupAddress = pickupAddress;
			}
			if (destinationAddress) {
				ltlContext.destinationAddress = destinationAddress;
			}

			ltlContext.stepOneFormValues = {
				pickup_date: data.pickup_date ? dayjs(data.pickup_date) : undefined, // 提货日期
				items:
					data.items.length > 0
						? data.items.map((item) => ({ ...item, id: Math.random().toString() }))
						: [],
				pickup_accessorials: data.pickup_accessorials, // 提货附加服务
				destination_accessorials: data.destination_accessorials, // 目的地附加服务
				shipment_accessorials: data.shipment_accessorials, // 运输附加服务
			};
			ltlContext.calcItemFreightClass();
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			endLoading();
			closeModal();
		}
	});

	return (
		<>
			<Button
				style={{ backgroundColor: '#6B33FE', border: 'unset' }}
				className='flex gap-[4px] items-center'
				onClick={() => {
					openModal();
				}}
			>
				<Shining10pxIcon />
				<span className='body-4-m text-white'>AI {t(`Autofill`)}</span>
			</Button>
			{openingModal && (
				<Modal
					open={openingModal}
					title={t(`Autofill`) + ' ' + t(`Information`)}
					okText={t(`Confirm`)}
					cancelText={t(`Cancel`)}
					closable
					confirmLoading={loading}
					okButtonProps={{ className: 'p-[5px_20px] h-fit body-3-sb text-white' }}
					cancelButtonProps={{ className: 'p-[5px_20px] h-fit body-3-sb' }}
					onCancel={() => closeModal()}
					onOk={() => handleAIAutofill()}
				>
					<Input.TextArea
						rows={16}
						placeholder={t('AUTOFILL_PLACEHOLDER_TEXT')}
						className='mt-[24px]'
						value={content}
						onKeyDown={handleContentKeydown}
						onChange={(e) => setContent(e.target.value)}
					/>
					<Gap height='12px' />
					<div className='foot-note-1-r text-grey-01'>
						<div>{t(`Disclaimer`)}:</div>
						<div>
							{t(
								`Please double-check all entries to ensure accuracy before finalizing any documents or submissions. We appreciate your understanding and encourage you to verify all details provided by the AI autofill feature.`,
							)}
						</div>
					</div>
				</Modal>
			)}
		</>
	);
});
