import { Button, Form, Tooltip } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import Input from '@/components/input';

const Item = Form.Item;

export default () => {
	const form = Form.useFormInstance();

	const contactEmails: any[] = form.getFieldValue('contact_emails') ?? [];

	return (
		<div>
			<div className='ant-form-item-label flex justify-between items-center'>
				<label>
					{t('Extra Contact Emails')}{' '}
					<Tooltip title={t('Extra_contract_email_info')}>
						<InfoCircleOutlined />
					</Tooltip>
				</label>

				<Button
					type='link'
					onClick={() => form.setFieldValue('contact_emails', [...contactEmails, ''])}
				>
					+ {t('Add Email')}
				</Button>
			</div>

			{contactEmails.map((email, index) => (
				<div className='flex gap-[26px]' key={index}>
					<Item
						name={['contact_emails', index]}
						className='flex-grow'
						rules={[{ type: 'email', message: t('Not a valid email address') }]}
					>
						<Input />
					</Item>
					<Item>
						<Button
							type='text'
							icon={
								<DeleteOutlined
									onClick={() => {
										contactEmails.splice(index, 1);
										form.setFieldValue('contact_emails', [...contactEmails]);
									}}
								/>
							}
						/>
					</Item>
				</div>
			))}
		</div>
	);
};
