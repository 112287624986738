import { useTranslation } from 'react-i18next';
import { RequestedRateContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import RateRequestTable from './components/rate-request-table';
import SearchForm from './components/search-form';
import Panel from '@/components/panel';

const RequestedRateApp = observer(() => {
	const { t } = useTranslation();

	return (
		<div className=' box-border p-[51px_40px_0_40px] h-screen '>
			<div className='h-full relative overflow-hidden'>
				<div className='mb-[12px] h3-b'>{t('Rate Request')}</div>
				<Panel classNames='h-[calc(100%_-_47px)] flex flex-col'>
					{/* search form */}
					<div>
						<SearchForm />
					</div>
					{/* table */}
					<div className='flex-grow pl-[32px] pr-[32px] h-[calc(100%_-_98px)]'>
						<RateRequestTable />
					</div>
				</Panel>
			</div>
		</div>
	);
});

export default observer(() => {
	return (
		<RequestedRateContext>
			<RequestedRateApp />
		</RequestedRateContext>
	);
});
