import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import * as _ from 'lodash';
import { businessStatusMap, Shipment, ShipmentItem } from '@/interfaces/shipment.interface';
import { calcTotalWeight } from '@/services/contexts/ltl/ltl.context';
import { useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import Panel from '@/components/panel';
import { apiHooks } from '@/hooks/apis';
import useLtlOrdersParams from './use-ltl-orders-params';

export default observer(({ mode }: { mode?: string }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useLtlOrdersParams();

	const {
		runAsync: getShipmentList,
		data: shipmentList,
		loading: shipmentListLoading,
		pagination,
	} = mode == 'agent' ? apiHooks.useGetAgentShipmentList() : apiHooks.useGetShipmentList();

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const shipmentOrderColumns: ColumnsType<Shipment> = [
		{
			title: t('Shipment ID'),
			dataIndex: 'id',
			width: '130px',
		},
		{
			title: t('User'),
			key: 'user',
			dataIndex: ['user', 'name'],
			width: '80px',
		},
		{
			title: t('Status'),
			dataIndex: 'business_status',
			width: '80px',
			render(data) {
				return <div>{t(businessStatusMap[data]) ?? ''}</div>;
			},
		},
		{
			title: t('PickUp'),
			dataIndex: ['estimated_pickup_date'],
			width: '100px',
			render(date: string, record) {
				return (
					<div>
						<div>{record.pickup_company_name}</div>
						<div>{dayjs(date).format('MM/DD/YYYY')}</div>
					</div>
				);
			},
		},
		{
			title: t('Delivery'),
			dataIndex: ['estimated_delivery_date'],
			width: '100px',
			render(date: string, record) {
				return (
					<div>
						<div>{record.destination_company_name}</div>
						<div>{dayjs(date).format('MM/DD/YYYY')}</div>
					</div>
				);
			},
		},
		{
			title: t('Items'),
			dataIndex: ['items'],
			width: '100px',
			render(items: ShipmentItem[]) {
				return <div>{`${calcTotalWeight(items)} lbs in total`}</div>;
			},
		},
	];

	const _shipmentOrderColumns = useMemo(() => {
		return shipmentOrderColumns.filter((column) => {
			return !(mode != 'agent' && column.key === 'user');
		});
	}, [shipmentOrderColumns, mode]);

	const handleChange: TableProps<Shipment>['onChange'] = useMemoizedFn((pagination) => {
		const { current, pageSize } = pagination;
		changePagination(current, pageSize);

		changeSearchParams({
			page: current,
			per_page: pageSize,
		});
	});

	useMount(() => {
		changePagination(parsedSearchParams.page, parsedSearchParams.per_page);
		getShipmentList(
			{ pageSize: parsedSearchParams.per_page, current: parsedSearchParams.page },
			{ params: {} },
		);
	});

	return (
		<div className=' box-border p-[51px_40px_0_40px] h-screen flex flex-col gap-[12px]'>
			<div className='flex justify-between items-center'>
				<div className='h3-b'>{t('LTL Orders')}</div>
				{/* <Button type='primary' onClick={() => openAddressModal()}>
          {t('New Address')}
        </Button> */}
			</div>

			<Panel classNames='h-[calc(100%_-_47px)]'>
				{/* <div className='mb-[20px] pl-[16px] flex light-border rounded-[5px]'>
          <SearchOutlined width={20} />
          <Input
            placeholder={t(
              'Search for ID, Name, Code, City, Address, D/O Address, Phone/Email....',
            )}
            variant='borderless'
            onChange={(e) => {
              // handleSearch({ keyword: e.target.value });
            }}
          />
        </div> */}

				<div className='h-full'>
					<Table
						columns={_shipmentOrderColumns}
						dataSource={shipmentList}
						loading={shipmentListLoading}
						className='auto-h-full-table  custom-table'
						pagination={{
							position: ['bottomCenter'],
							total,
							current,
							pageSize: 20,
						}}
						rowClassName='cursor-pointer'
						onRow={(record) => ({
							onClick: () => {
								navigate(`/ltl-orders/${record.id}`);
							},
						})}
						onChange={handleChange}
					/>
				</div>

				{/* <AddressModal onAfterSave={() => handleSearch(parsedSearchParams)} /> */}
			</Panel>
		</div>
	);
});
