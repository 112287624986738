import { Company } from '@/interfaces/company.interface';
import { getAgentCompanies } from '@/services/apis/get-agent-companies';
import { getAgentCompany } from '@/services/apis/get-agent-company';
import { makeAutoObservable, runInAction } from 'mobx';

class AgentContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _companies: Company[];
	private _agentCompany: Company;

	get companies() {
		return this._companies || [];
	}

	set companies(data: Company[]) {
		runInAction(() => {
			this._companies = data;
		});
	}

	get agentCompany() {
		return this._agentCompany;
	}

	set agentCompany(agentCompany: Company) {
		this._agentCompany = agentCompany;
	}

	async fetchCompanies() {
		const res = await getAgentCompanies();
		console.log(res);
		this.companies = res.data.data;
	}

	async fetchAgentCompany() {
		const res = await getAgentCompany();
		this.agentCompany = res.data.data;
	}

	public async init() {
		console.log('Initializing AgentContext...');
		await this.fetchCompanies();
		// await this.fetchAgentCompany();
	}
}

export const agent = new AgentContext();
