import { Space, Tooltip } from 'antd';
import BoatIcon from '@/icons/boat-icon';
import CargoIcon from '@/icons/cargo-icon';
import CargoIconActive from '@/icons/cargo-icon-active';
import InfoIcon from '@/icons/info-icon';
import OrderLocationIcon from '@/icons/order-location-icon';
import OrderLocationIconActive from '@/icons/order-location-icon-active';
import OrderWarehouseIcon from '@/icons/order-warehouse-icon';
import OrderWarehouseIconActive from '@/icons/order-warehouse-icon-active';
import TrainIcon from '@/icons/train-icon';
import TrainIconActive from '@/icons/train-icon-active';
import TruckIcon from '@/icons/truck-icon';
import TruckIconActive from '@/icons/truck-icon-active';
import { Container, Order } from '@/interfaces/order.interface';
import { DownOutlined, UpOutlined, WarningOutlined } from '@ant-design/icons';
import { useBoolean } from 'ahooks';
import clsx from 'clsx';
import { t } from 'i18next';
import ContainerReleaseStatusIcon from '@/components/container-release-status-icon';
import { Gap } from '@/components/gap';
import { Table, TableProps } from '@/components/table';
import { Box } from '../box';
import ContainerStatusTimeline from './container-status-timeline';
import './index.less';
import ShipmentUpdateStep from './shipment-update-step';
import { Item, StepsProps } from './shipment-update-step/type';
import useViewHistoryModal from './use-view-history-modal';

interface TrackingProps {
	order: Order;
	container: Container;
	boxProps?: {
		className?: string;
		style?: React.CSSProperties;
	};
}

const ViewHistory = ({ onClick }: { onClick: () => void }) => (
	<span onClick={onClick} className='body-4-m-underline text-grey-01 cursor-pointer'>
		{t('View History') + '(' + t('For reference only') + ')'}
	</span>
);

// eslint-disable-next-line react-refresh/only-export-components
export default ({ order, container, boxProps }: TrackingProps) => {
	const { openTimelineModal, TimelineModal } = useViewHistoryModal();

	const {
		number,
		seal_number,
		is_arrived_at_pod,
		is_arrived_at_final_port,
		port_of_loading_etd,
		port_of_discharge_eta,
		final_port_eta,
		schedule_terminal_pickup_at,
		schedule_terminal_pickup_at_reserve_time,
		actual_terminal_pickuped_at,
		schedule_delivery_at,
		schedule_delivery_at_reserve_time,
		actual_delivered_at,
		empty_returned_at,
		actual_empty_returned_at,
		freight_released_at,
		customs_released_at,
		terminal_released_at,
		ready_to_pickup_at,
		rail_departure_date,
		shipping_events,
		lfd,
		trouble_status,
	} = container;

	const forceActive = !!actual_empty_returned_at;

	const release_statuses = [
		{
			label: t('SSL Released'),
			value: freight_released_at,
		},
		{
			label: t('Custom Released'),
			value: customs_released_at,
		},
		{
			label: t('Terminal Released'),
			value: terminal_released_at,
		},
		{
			label: t('Available to Pickup'),
			value: ready_to_pickup_at,
		},
	];

	const showItems = [
		'port_of_loading_etd',
		'port_of_discharge_eta',
		container.destination_type === 2 ? 'final_port_eta' : undefined,
		'actual_terminal_pickuped_at',
		'actual_delivered_at',
		'actual_empty_returned_at',
	].filter((i) => !!i) as string[];

	const itemMap: Record<string, Item> = {
		port_of_loading_etd: {
			title: t('Vessel Departed'),
			value: port_of_loading_etd,
			content: port_of_loading_etd,
			Icon: () => <BoatIcon />,
		},
		port_of_discharge_eta: {
			title: t('Arrived at Port'),
			value: is_arrived_at_pod,
			content: () => {
				if (is_arrived_at_pod) {
					return (
						<div>
							<div>{port_of_discharge_eta}</div>
							{rail_departure_date && (
								<div>
									<small>
										({t('Rail departure at')} {rail_departure_date})
									</small>
								</div>
							)}
						</div>
					);
				} else {
					return (
						<span className='text-grey-01'>
							<Tooltip title={t('ETA')}>
								<InfoIcon className='mr-[4px]' />
							</Tooltip>

							{port_of_discharge_eta}
						</span>
					);
				}
			},
			Icon: ({ status }) =>
				status === 'waiting' && !forceActive ? <CargoIcon /> : <CargoIconActive />,
		},
		final_port_eta: {
			title: t('Arrived at Ramp'),
			value: is_arrived_at_final_port,
			content: () => {
				if (is_arrived_at_pod) {
					return final_port_eta;
				} else {
					return (
						<span className='text-grey-01'>
							<Tooltip title={t('ETA')}>
								<InfoIcon className='mr-[4px]' />
							</Tooltip>

							{final_port_eta}
						</span>
					);
				}
			},
			Icon: ({ status }) =>
				status === 'waiting' && !forceActive ? <TrainIcon /> : <TrainIconActive />,
		},
		actual_terminal_pickuped_at: {
			title: t('Pickup'),
			value: actual_terminal_pickuped_at,
			content: () => {
				if (actual_terminal_pickuped_at) {
					return actual_terminal_pickuped_at;
				}
				if (schedule_terminal_pickup_at) {
					return (
						<span className='text-grey-01'>
							<Tooltip title={t('Schedule At')}>
								<InfoIcon className='mr-[4px]' />
							</Tooltip>
							{schedule_terminal_pickup_at}
							<br />
							<span className='ml-2'>{schedule_terminal_pickup_at_reserve_time}</span>
						</span>
					);
				}
				return '';
			},
			Icon: ({ status }) =>
				status === 'waiting' && !forceActive ? <TruckIcon /> : <TruckIconActive />,
			extra: lfd && (
				<div className='foot-note-1-m text-updated-orange'>
					{t('Last Free Day:')}
					{lfd}
				</div>
			),
		},
		actual_delivered_at: {
			title: t('Delivered'),
			value: actual_delivered_at,
			content: () => {
				if (actual_delivered_at) {
					return actual_delivered_at;
				}
				if (schedule_delivery_at) {
					return (
						<span className='text-grey-01 text-center'>
							<Tooltip title={t('Schedule At')}>
								<InfoIcon className='mr-[4px]' />
							</Tooltip>
							{schedule_delivery_at}
							<br />
							<span className='ml-2'>{schedule_delivery_at_reserve_time}</span>
						</span>
					);
				}
				return '';
			},
			Icon: ({ status }) =>
				status === 'waiting' && !forceActive ? (
					<OrderWarehouseIcon />
				) : (
					<OrderWarehouseIconActive />
				),
		},
		actual_empty_returned_at: {
			title: t('Empty Returned'),
			value: actual_empty_returned_at,
			content: () => {
				if (actual_empty_returned_at) {
					return actual_empty_returned_at;
				}
				if (empty_returned_at) {
					return (
						<span className='text-grey-01'>
							<Tooltip title={t('Schedule At')}>
								<InfoIcon className='mr-[4px]' />
							</Tooltip>

							{empty_returned_at}
						</span>
					);
				}
				return '';
			},
			Icon: ({ status }) =>
				status === 'waiting' && !forceActive ? <OrderLocationIcon /> : <OrderLocationIconActive />,
		},
	};

	const shipmentUpdateItems: StepsProps['items'] = showItems.map((key) => itemMap[key]);

	const getCurrentIndex = () => {
		const currentIndex =
			(container.destination_type === 2
				? [
						port_of_loading_etd,
						is_arrived_at_pod,
						is_arrived_at_final_port,
						actual_terminal_pickuped_at,
						actual_delivered_at,
						actual_empty_returned_at,
				  ]
				: [
						port_of_loading_etd,
						is_arrived_at_pod,
						actual_terminal_pickuped_at,
						actual_delivered_at,
						actual_empty_returned_at,
				  ]
			).findLastIndex((i) => !!i) + 1;
		return currentIndex;
	};

	const [openingException, { toggle: toggleException }] = useBoolean(!!trouble_status?.length);

	const exceptionColumns: TableProps<{ number: string; exception: string }>['columns'] = [
		{
			title: t(`Container #`),
			dataIndex: 'number',
		},
		{
			title: t(`Exceptions`),
			dataIndex: 'exception',
			CellRender: ({ value, style, className }) => (
				<div style={style} className={`${className} h-full`}>
					<div
						className={`min-w-[120px] h-[28px] p-[0_18px] leading-[28px] rounded-[8px] bg-secondary-light-02 body-4-sb text-additional-warning whitespace-nowrap`}
					>
						{value}
					</div>
				</div>
			),
		},
	];

	return (
		<div className='flex flex-col'>
			{trouble_status?.length > 0 && (
				<Box
					title={
						<div className='flex items-center text-additional-warning'>
							{t('Exception')}
							<Gap width='10px' />
							<WarningOutlined />
						</div>
					}
					extra={
						<div className='flex items-center'>
							<span className='h4-b text-additional-warning'>{trouble_status?.length ?? 0}</span>
							<Gap width='4px' />
							<div className='flex items-center' onClick={toggleException}>
								{openingException ? (
									<UpOutlined className='text-[12px]' />
								) : (
									<DownOutlined className='text-[12px]' />
								)}
							</div>
						</div>
					}
					{...boxProps}
				>
					{openingException && (
						<Table
							columns={exceptionColumns}
							dataSource={(trouble_status ?? []).map((t) => ({ number, exception: t }))}
							commonCellClass='h-[40px] flex items-center'
							commonCellStyle={{
								height: '40px',
								borderBottom: '1px solid #9BA5B7',
							}}
							commonHeaderClass='foot-note-1-m text-grey-01'
							commonBodyClass='foot-note-1-r text-black'
						/>
					)}
				</Box>
			)}

			<Box
				title={t('Shipment Update')}
				extra={<ViewHistory onClick={() => openTimelineModal(shipping_events)} />}
				className={clsx('flex-grow-0', boxProps?.className)}
				style={boxProps?.style}
			>
				{/* <div className='mt-[4px] mb-[32px]'>{t('Discharged (Port of Transshipment)')}</div> */}
				<div className='w-full flex overflow-x-auto mt-[16px]'>
					<div className='flex-grow flex-shrink' />
					<ShipmentUpdateStep
						items={shipmentUpdateItems}
						current={getCurrentIndex()}
						className='flex-grow-0 flex-shrink-0'
					/>
					<div className='flex-grow flex-shrink' />
				</div>
			</Box>

			<Box
				title={t('Release Status')}
				className={clsx('flex-grow-0', boxProps?.className)}
				style={boxProps?.style}
			>
				<Space className='mt-[16px] w-full' size={36}>
					{release_statuses.map(({ label, value }) => {
						return (
							<div className='flex items-center'>
								<span className='mr-[10px]'>
									<ContainerReleaseStatusIcon
										time={actual_empty_returned_at || actual_terminal_pickuped_at ? true : value}
										eta={order?.port_of_discharge_eta}
									/>
								</span>
								<span>{label}</span>
							</div>
						);
					})}
				</Space>
			</Box>

			<Box
				title={t('Container Status')}
				className={clsx('grow', boxProps?.className)}
				style={boxProps?.style}
			>
				<ContainerStatusTimeline container={container} />
			</Box>

			<TimelineModal />
		</div>
	);
};
