import { useLTLContext } from '@/services/contexts';
import { useBoolean, useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { apiHooks } from '@/hooks/apis';
import { message } from '@/utils/message';
import useLtlSearchRateParams from './use-ltl-search-rate-params';

export const useInitQuote = () => {
	const ltlContext = useLTLContext();

	const { parsedSearchParams } = useLtlSearchRateParams();

	const [loading, { setTrue: startLoading, setFalse: endLoading }] = useBoolean(true);

	const { runAsync: getQuote } = apiHooks.useGetQuote({ manual: true });

	const { runAsync: searchCity } = apiHooks.useSearchCity();

	const initQuote = useMemoizedFn(async () => {
		try {
			startLoading();

			const res = await getQuote({
				path: {
					id: parsedSearchParams.quote_id,
				},
			});

			const data = res.data.data;

			let pickupAddress: typeof ltlContext.pickupAddress,
				destinationAddress: typeof ltlContext.destinationAddress;

			if (data.pickup_city_name || data.pickup_zipcode) {
				let cityName = '';
				if (data.pickup_city_name) {
					cityName = data.pickup_city_name;
					if (data.pickup_state_name) {
						cityName += `, ${data.pickup_state_name} `;
					}
				}
				if (data.pickup_zipcode) {
					cityName += `${data.pickup_zipcode}`;
				}
				const pickupCityRes = await searchCity({ path: { cityName } });

				if (pickupCityRes.data.data.length > 0) {
					const _pickupCity = pickupCityRes.data.data[0];
					pickupAddress = {
						id: -1,
						name: '',
						code: null,
						zipcode: _pickupCity.zipcode,
						is_residential: false,
						address: '',
						address2: '',
						special_request: '',
						city: _pickupCity,
						country: _pickupCity.country,
						customer_memo: '',
						appointment_by: -1,
						created_at: '',
						updated_at: '',
						delivery_order_address: '',
						contacts: [
							// @ts-ignore
							{
								name: '',
								email: '',
								phone: '',
							},
						],
						// @ts-ignore
						user: {},
					};
				}
			}

			if (data.destination_city_name || data.destination_zipcode) {
				let cityName = '';
				if (data.destination_city_name) {
					cityName = data.destination_city_name;
					if (data.destination_state_name) {
						cityName += `, ${data.destination_state_name} `;
					}
				}
				if (data.destination_zipcode) {
					cityName += `${data.destination_zipcode}`;
				}
				const destinationCityRes = await searchCity({ path: { cityName } });

				if (destinationCityRes.data.data.length > 0) {
					const _destinationCity = destinationCityRes.data.data[0];
					destinationAddress = {
						id: -1,
						name: '',
						code: null,
						zipcode: _destinationCity.zipcode,
						is_residential: false,
						address: '',
						address2: '',
						special_request: '',
						city: _destinationCity,
						country: _destinationCity.country,
						customer_memo: '',
						appointment_by: -1,
						created_at: '',
						updated_at: '',
						delivery_order_address: '',
						contacts: [
							// @ts-ignore
							{
								name: '',
								email: '',
								phone: '',
							},
						],
						// @ts-ignore
						user: {},
					};
				}
			}

			if (pickupAddress) {
				ltlContext.pickupAddress = pickupAddress;
			}
			if (destinationAddress) {
				ltlContext.destinationAddress = destinationAddress;
			}

			ltlContext.stepOneFormValues = {
				pickup_date: data.pickup_date ? dayjs(data.pickup_date) : undefined, // 提货日期
				items:
					data.items.length > 0
						? data.items.map((item) => ({ ...item, id: Math.random().toString() }))
						: [],
				pickup_accessorials: data.pickup_accessorials, // 提货附加服务
				destination_accessorials: data.destination_accessorials, // 目的地附加服务
				shipment_accessorials: data.shipment_accessorials, // 运输附加服务
			};
			ltlContext.calcItemFreightClass();
			ltlContext.quote = data;
			ltlContext.step = 2;
		} catch (err) {
			message.error(err.data?.message || err.data?.error);
		} finally {
			endLoading();
		}
	});

	useMount(() => {
		if (!ltlContext.quote && parsedSearchParams.quote_id) {
			initQuote();
		} else {
			endLoading();
		}
	});

	return {
		loading,
	};
};
