import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CargoQuery } from '@/services/apis/get-requested-rates/interface';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';

export interface AddressQuery {
	per_page?: number;
	page?: number;
	keyword?: string;
}

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'per_page', searchParams.per_page, (v) => (v ? Number(v) : 20));
		valueTransfer(res, 'page', searchParams.page, (v) => (v ? Number(v) : 1));

		return res as Partial<AddressQuery>;
	}, [searchParams]);

	const changeSearchParams = useCallback((values: any) => {
		setSearchParams(qs.stringify({ ...values }));
	}, []);

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
