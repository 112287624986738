/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from 'react';
import {
	Button,
	Col,
	Divider,
	Drawer,
	Form,
	Input,
	InputNumber,
	message,
	Row,
	Select,
	Typography,
} from 'antd';
import { User } from '@/interfaces/user.interface';
import { createUser } from '@/services/apis/create-agent-user';
import { updateUser } from '@/services/apis/update-agent-user';
import { useAgentContext } from '@/services/contexts/agent';
import { userContext } from '@/services/contexts/user';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { camelCase, get, startCase } from 'lodash';
import { observer } from 'mobx-react-lite';
import CompanySelect from '@/pages/agent-users/use-company-select';
import { UserModalProps } from './type';

const UNIT_OPTIONS = [
	{ label: '1 Day', value: 86400 },
	{ label: '1 Week', value: 604800 },
	{ label: '2 Week', value: 1209600 },
	{ label: '1 Month', value: 2592000 },
];

const RATE_RULE_TYPES = [
	{ label: 'fix', value: 'fix' },
	{ label: 'percentage', value: 'percentage' },
];

const Item = Form.Item;

const Index = () => {
	const agentContext = useAgentContext();

	useEffect(() => {
		agentContext.init();
	}, []);

	const [open, setOpen] = useState(false);

	const [user, setUser] = useState<any>();

	const openUserModal = useCallback((user?: User) => {
		setOpen(true);
		setUser(user);
	}, []);

	const closeUserModal = () => {
		console.log('colse');
		setOpen(false);
		setUser(undefined);
	};

	const UserModal = observer(({ onAfterSave, ...props }: UserModalProps) => {
		const [confirmLoading, setConfirmLoading] = useState(false);

		const [form] = Form.useForm<User>();

		const [editUser, setEditUser] = useState<User | null>(user || null);

		const isCreating = !editUser;

		const handleFinish = async (values: any) => {
			values = { ...values, city_id: values?.city_id?.value };

			setConfirmLoading(true);

			const action = isCreating ? 'Create' : 'Update';

			let res;
			try {
				if (isCreating) {
					res = await createUser({ data: values });
				} else {
					res = await updateUser({
						data: values,
					});
				}

				message.success(t(`${action} user success`));
				onAfterSave && onAfterSave(res?.data?.data);
				closeUserModal();
			} catch (error) {
				message.error(t(`${action} user error: ${get(error, 'response.data.message')}`));
			} finally {
				setConfirmLoading(false);
			}
		};

		useEffect(() => {
			if (!editUser) return;
			form.setFieldsValue({ ...editUser });
		}, [editUser]);

		const getRateRuleLabel = (name: string) => {
			return startCase(camelCase(name));
		};

		return (
			<Drawer
				{...props}
				open={open}
				title={isCreating ? t('Add a New User') : t('Edit User')}
				width={'80%'}
				footer={null}
				onClose={closeUserModal}
			>
				<Form
					form={form}
					onFinish={handleFinish}
					layout='vertical'
					initialValues={
						editUser
							? {
									...editUser,
							  }
							: {
									additional_notification_emails: [''],
									rate_rules: [
										{
											type: 'fix',
											value: '',
											name: 'base_rate',
										},
										// {
										// 	type: 'fix',
										// 	value: '',
										// 	name: 'chassis_fee',
										// },
										// {
										// 	type: 'fix',
										// 	value: '',
										// 	name: 'chassis_split_fee',
										// },
									],
							  }
					}
				>
					<Row gutter={80}>
						<Item name='id' hidden>
							<Input />
						</Item>
						<Col span={12}>
							<Row gutter={15}>
								<Col span={24}>
									<h4>Basic Information</h4>
								</Col>
								<Col span={24}>
									<Row gutter={15}>
										<Col span={24}>
											<Item name='name' label={t('Name')} rules={[{ required: true }]}>
												<Input placeholder='Enter name' />
											</Item>
										</Col>
										<Col span={24}>
											<Item noStyle shouldUpdate>
												{({ getFieldValue, setFieldValue }) => (
													<Item
														name='company_id'
														label={t('Company')}
														rules={[
															{
																required: true,
																message: 'Please select a company',
															},
														]}
													>
														<CompanySelect
															selected={getFieldValue('company_id')}
															onSelect={(item) => {
																setFieldValue('company_id', item);
															}}
														/>
													</Item>
												)}
											</Item>
										</Col>
										<Col span={24}>
											<Item noStyle shouldUpdate>
												{({ getFieldValue, setFieldValue }) => (
													<Item
														name='bill_to_id'
														label={t('Bill To')}
														rules={[
															{
																required: true,
																message: 'Please select a bill',
															},
														]}
													>
														<CompanySelect
															selected={getFieldValue('bill_to_id')}
															onSelect={(item) => {
																console.log(item);
																setFieldValue('bill_to_id', item);
															}}
														/>
													</Item>
												)}
											</Item>
										</Col>
										<Col span={24}>
											<Item name='email' label={t('Email')} rules={[{ required: true }]}>
												<Input type='email' placeholder='Enter email' required />
											</Item>
										</Col>
										<Col span={24}>
											{/* <Item noStyle shouldUpdate>
												{({ getFieldValue, setFieldValue }) => (
													<>
														<Item hidden name={'additional_notification_emails'}>
															<Input />
														</Item>
														<Typography.Text>
															{t('Additional Emails')}{' '}
															<Button
																size='small'
																onClick={() =>
																	setFieldValue('additional_notification_emails', [
																		...(getFieldValue('additional_notification_emails') || []),
																		'',
																	])
																}
															>
																Add
															</Button>
														</Typography.Text>
														<br />
														<br />
														{getFieldValue('additional_notification_emails')?.map(
															(email: string, index: number) => (
																<Item key={index}>
																	<Input
																		placeholder='Enter additional email'
																		value={email}
																		onChange={(e) => {
																			const newEmails = [
																				...getFieldValue('additional_notification_emails'),
																			];
																			newEmails[index] = e.target.value;
																			setFieldValue('additional_notification_emails', newEmails);
																		}}
																	/>
																</Item>
															),
														)}
													</>
												)}
											</Item> */}
											<Typography.Text>{t('Additional Emails')}</Typography.Text>
											<Form.Item noStyle shouldUpdate>
												{({ getFieldValue, setFieldsValue }) => (
													<Form.List name='additional_notification_emails'>
														{(fields, { add, remove }) => (
															<>
																{fields.map((field) => (
																	<Form.Item label={''} key={field.key}>
																		<Form.Item
																			wrapperCol={{ xs: 10 }}
																			{...field}
																			noStyle
																			rules={[
																				{
																					required: false,
																					message: 'Please input Email',
																				},
																			]}
																		>
																			<Input
																				minLength={5}
																				placeholder='Email'
																				style={{
																					width: fields.length > 1 ? '95%' : '100%',
																				}}
																			/>
																		</Form.Item>
																		{fields.length > 1 ? (
																			<MinusCircleOutlined
																				className='dynamic-delete-button'
																				style={{ marginLeft: '10px' }}
																				onClick={() => remove(field.name)}
																			/>
																		) : null}
																	</Form.Item>
																))}
																<Form.Item>
																	<Button
																		type='dashed'
																		onClick={() => add()}
																		style={{
																			width: '100%',
																		}}
																		icon={<PlusOutlined />}
																	>
																		Add
																	</Button>
																</Form.Item>
															</>
														)}
													</Form.List>
												)}
											</Form.Item>
										</Col>
										<Divider />
									</Row>
								</Col>
							</Row>
						</Col>
						<Col span={12}>
							<Row gutter={15}>
								<Col span={24}>
									<Item name='password' label={t('Password')} rules={[]} hasFeedback>
										<Input.Password placeholder='Enter password' />
									</Item>
								</Col>
								<Col span={24}>
									<Item
										name='password_confirmation'
										label={t('Password Confirmation')}
										dependencies={['password']}
										hasFeedback
										rules={[
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error('The two passwords that you entered do not match!'),
													);
												},
											}),
										]}
									>
										<Input.Password placeholder='Confirm password' />
									</Item>
								</Col>
								<Col span={24}>
									<Item name='memo' label={t('Memo')}>
										<Input.TextArea placeholder='Enter Memo' rows={4} />
									</Item>
								</Col>
								<Col span={24}>
									<h4>Limit</h4>
								</Col>
								<Col span={12}>
									<Form.Item
										label='Rate Limit'
										name='rate_search_limit'
										rules={[
											{
												required: true,
												message:
													'Please Input rate limit that less than or equal to ' +
													userContext.getUserInfo().rate_search_limit,
											},
											() => ({
												validator(_, value) {
													if (value <= userContext.getUserInfo().rate_search_limit) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(
															'Rate limit must be less than or equal to ' +
																userContext.getUserInfo().rate_search_limit,
														),
													);
												},
											}),
										]}
									>
										<InputNumber min={0} placeholder='Enter rate limit' className='w-full' />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label={t('Unit')}
										name='rate_search_limit_decay_seconds'
										rules={[
											{
												required: true,
												message: 'Please Select rate search limit unit',
											},
										]}
									>
										<Select>
											{UNIT_OPTIONS.map((option: { value: number; label: string }) => (
												<Select.Option key={option.value + option.label} value={option.value}>
													{option.label}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label='Requested Rate Create Limit'
										name='requested_rate_create_limit'
										rules={[
											{
												required: true,
												message: 'Please Input requested rate create limit',
											},
											() => ({
												validator(_, value) {
													if (value <= userContext.getUserInfo().requested_rate_create_limit) {
														return Promise.resolve();
													}
													return Promise.reject(
														new Error(
															'Requested rate create limit must be less than or equal to ' +
																userContext.getUserInfo().requested_rate_create_limit,
														),
													);
												},
											}),
										]}
									>
										<InputNumber
											min={0}
											placeholder='Enter requested rate create limit'
											className='w-full'
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label='Unit'
										name='requested_rate_create_limit_decay_seconds'
										rules={[
											{
												required: true,
												message: 'Please Select requested rate create limit unit',
											},
										]}
									>
										<Select>
											{UNIT_OPTIONS.map((option: { value: number; label: string }) => (
												<Select.Option key={option.value + option.label} value={option.value}>
													{option.label}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}></Col>
								<Col span={12}>
									<div className='flex justify-end'>
										<Button type='primary' htmlType='submit' loading={confirmLoading}>
											{t('Save')}
										</Button>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Drawer>
		);
	});

	return {
		UserModal,
		openUserModal,
		closeUserModal,
	};
};

export default Index;
