import { useLTLContext } from '@/services/contexts';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { MapBox } from '@/pages/search-rate/components/mapbox';

export const ShipmentInfoMap = observer(() => {
	const ltlContext = useLTLContext();

	console.log(
		`[ShipmentInfoMap]`,
		toJS(ltlContext.pickupAddress.city),
		toJS(ltlContext.destinationAddress.city),
	);

	return (
		<div className='w-full h-full bg-primary-dark-01'>
			<MapBox
				start={ltlContext.pickupAddress.city}
				to={ltlContext.destinationAddress.city}
				components={{ distance: true }}
			/>
		</div>
	);
});
