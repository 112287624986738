import clsx from 'clsx';
import { t } from 'i18next';

interface OrderReleaseStatusLabel {
	status: string;
	isSelected: boolean;
}

const styleMap: Record<string, string> = {
	// ['Completed']: 'text-updated-green bg-[#DFF0DF]',
	['Cancelled']: 'text-grey-01 bg-grey-05',
	['Blocked']: 'text-updated-red bg-[#FFE0E0]',
	['In Transit']: 'text-primary-regular bg-[#007BFF26]',
};

export default ({ status, isSelected }: OrderReleaseStatusLabel) => {
	const className = styleMap[status];

	// return (
	// 	<div
	// 		className={clsx(
	// 			'p-[6px_16px_6px_16px] w-[94px] h-[28px] flex-center rounded-[4px] foot-note-1-m ',
	// 			className,
	// 		)}
	// 	>
	// 		{t(status)}
	// 	</div>
	// );

	return (
		<div
			className={clsx(
				'p-[8px_16px_0px_0px] h-[28px] rounded-[4px] foot-note-1-m ',
				className,
				isSelected ? 'text-black' : '',
			)}
		>
			{t(status)}
		</div>
	);
};
